import { onMounted, onUnmounted } from 'vue';
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import zhLocale from "element-plus/es/locale/lang/zh-cn";
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {
    const locale = zhLocale;
    onMounted(() => {
      // 添加刷新事件-关闭时(刷新、返回)执行
      window.addEventListener("beforeunload", () => saveStore());
    });
    onUnmounted(() => {
      // 卸载刷新事件
      window.removeEventListener("beforeunload", () => saveStore());
    });
    const saveStore = () => {
      // 缓存token
      sessionStorage.setItem("token", "");
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view, null, {
          default: _withCtx(({
            Component
          }) => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
          _: 1
        })]),
        _: 1
      }, 8, ["locale"]);
    };
  }
});